<template>
  <div class="tab-top">
    <div class="tab-flex">
      <div class="tab-logo">
        <img src="../assets/logo.png" alt="" />
        祥辰云
      </div>
      <div style="width: 50%;">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">平台导航</el-menu-item>
          <el-menu-item index="2">演示系统</el-menu-item>
          <el-menu-item index="3">APP下载</el-menu-item>
          <el-menu-item index="4">了解祥辰</el-menu-item>
        </el-menu>
      </div>
      <div>
        <!-- <el-button type="primary" plain>登录</el-button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  props: {
    msg: String,
  },
  data() {
    return {
        activeIndex: '1'
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      if(key === '1') {
        document.getElementById("a1").scrollIntoView()
      } else if(key === '2') {
        document.getElementById("a2").scrollIntoView()
      } else if(key === '3') {
        document.getElementById("a3").scrollIntoView()
      } else if(key === '4') {
        document.getElementById("a4").scrollIntoView()
      }
    }
  }
};
</script>

<style scoped lang="less">
.tab-top {
  width: 100%;
  height: 72px;
  line-height: 72px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.04);
  .tab-flex {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
}
.tab-logo {
  font-size: 20px;
  font-weight: 600;
  color: #1d2129;
  font-family: "PingFangSC-Medium";
  img {
    width: 26px;
    height: 26px;
    vertical-align: middle;
  }
}
::v-deep .el-menu.el-menu--horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  height: 72px;
}
::v-deep .el-menu--horizontal>.el-menu-item {
   height: 32px;
   line-height: 32px;
   font-size: 16px;
   font-family: "PingFangSC-Medium";
   font-weight: 400;
   color: #1D2129;
}
::v-deep .el-menu--horizontal>.el-menu-item.is-active, ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    height: 32px;
    line-height: 32px;
    background: #2F5AFE;
    border-radius: 4px;
    color: #FFFFFF;
    border: 0;
}
::v-deep .el-button {
  width: 80px;
  height: 32px;
  border-radius: 4px;
  padding: 0;
}
::v-deep .el-button--primary.is-plain {
  border: 1px solid #2f5afe;
  background-color: #ffffff;
  font-size: 16px;
  font-family: "PingFangSC-Medium";
  font-weight: 600;
  color: #2f5afe;
}
::v-deep .el-button--primary.is-plain:hover {
  border: 1px solid #2f5afe;
  background: #2f5afe;
}
</style>
