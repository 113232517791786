<template>
  <div class="home">
    <Navigation />
    <div class="home-top">
      <div id="a1" class="top-title">平台导航</div>
      <div class="top-center">
        <div class="top-center-side">
          <div class="top-center-title">
            <img src="../assets/home/img_18.png" alt="">
            植信通系统
          </div>
          <div class="top-center-flex">
            <div class="center-flex-module" @click="zxtClicks">
              <img class="img0" src="../assets/home/img_2.png" alt="">
              <img class="img1" src="../assets/home/img_2_1.png" alt="">
              植信通云平台
            </div>
            <div class="center-flex-module" @click="nysqClick">
              <img class="img0" src="../assets/home/img_3.png" alt="">
              <img class="img1" src="../assets/home/img_3_1.png" alt="">
              农业四情信息服务云平台
            </div>
            <div class="center-flex-module" @click="zhsfClick">
              <img class="img0" src="../assets/home/img_4.png" alt="">
              <img class="img1" src="../assets/home/img_4_1.png" alt="">
              智慧林防云服务平台
            </div>
            <div class="center-flex-module" @click="mgbeClick">
              <img class="img0" src="../assets/home/img_5.png" alt="">
              <img class="img1" src="../assets/home/img_5_1.png" alt="">
              美国白蛾检测预警系统
            </div>
            <div class="center-flex-module" @click="qfhcClick">
              <img class="img0" src="../assets/home/img_6.png" alt="">
              <img class="img1" src="../assets/home/img_6_1.png" alt="">
              迁飞害虫精准雷达监测系统
            </div>
            <div class="center-flex-module" @click="byCkicks">
              <img class="img0" src="../assets/home/img_7.png" alt="">
              <img class="img1" src="../assets/home/img_7_1.png" alt="">
              白蚁全生命周期监控云平台
            </div>
            <div class="center-flex-module" @click="smtnClick">
              <img class="img0" src="../assets/home/img_8.png" alt="">
              <img class="img1" src="../assets/home/img_8_1.png" alt="">
              松墨天牛检测预警系统
            </div>
          </div>
        </div>
      </div>
      <div class="top-center-other">
        <div class="other-module" @click="gsClicks">
          <div class="top-center-title" style="padding-top: 20px;">
            <img class="top-center-img" src="../assets/home/img_9.png" alt="">
            古树名木智慧监管云平台
          </div>
          <div class="top-center-text">
            为管理者提供动态监测、周边环境、事件抓拍等实时数据
          </div>
        </div>
        <div class="other-module" @click="ymClicks">
          <div class="top-center-title" style="padding-top: 20px;">
            <img class="top-center-img" src="../assets/home/img_10.png" alt="">
            疫木处置全流程追溯平台
          </div>
          <div class="top-center-text">
            实行疫木定点集中处置和全过程跟踪监管,就地就近有序开展疫木无害化处置
          </div>
        </div>
        <div class="other-module" @click="kcClick">
          <div class="top-center-title" style="padding-top: 20px;">
            <img class="top-center-img" src="../assets/home/img_11.png" alt="">
            5G昆虫体验馆
          </div>
          <div class="top-center-text">
            昆虫体验馆展示各种昆虫在大自然里最自然最真实的状态，直观了解不同昆虫长相 
          </div>
        </div>
        <div class="other-module" @click="yjClick">
          <div class="top-center-title" style="padding-top: 20px;">
            <img class="top-center-img" src="../assets/home/img_12.png" alt="">
            有害生物智能监测预警系统
          </div>
          <div class="top-center-text">
            快速、及时地掌握有害生物发生情况，弥补人为监测在时间、能力方面的漏报风险
          </div>
        </div>
        <div class="other-module other-modules">
          更多平台敬请期待
        </div>
      </div>
    </div>
      <div id="a2" class="top-title" style="padding-top:24px;margin-top:125px;">演示系统</div>
        <el-carousel class="home-center">
          <el-carousel-item style="display: flex;justify-content: center;">
           <div class="center-setting">
            <div class="center-title">古树医院</div>
            <div class="center-text">古树名木保护，开启数字赋能新时代</div>
            <div><img class="center-img" src="../assets/home/img_13.png" alt=""></div>
            <div class="center-flex">
              <div class="center-border" @click="gsHospital">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M3.33341 9.99996C3.33341 6.31806 6.31818 3.33329 10.0001 3.33329C13.682 3.33329 16.6667 6.31806 16.6667 9.99996C16.6667 13.6819 13.682 16.6666 10.0001 16.6666C6.31818 16.6666 3.33341 13.6819 3.33341 9.99996ZM10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663ZM8.33664 6.36348C8.0789 6.21313 7.76044 6.21206 7.5017 6.36067C7.24295 6.50929 7.08342 6.7849 7.08342 7.08329V12.9166C7.08342 13.215 7.24295 13.4906 7.5017 13.6392C7.76044 13.7879 8.0789 13.7868 8.33664 13.6364L13.3366 10.7198C13.5927 10.5704 13.7501 10.2963 13.7501 9.99996C13.7501 9.70357 13.5927 9.42949 13.3366 9.28014L8.33664 6.36348ZM11.2629 9.99996L8.75008 11.4658V8.53416L11.2629 9.99996Z" fill="#2F5AFE"/>
                </svg>
                观看案例
              </div>
            </div>
          </div>
          <div class="center-setting">
            <div class="center-title">数字水稻</div>
            <div class="center-text">建设监测与预警智慧平台</div>
            <div><img class="center-img" src="../assets/home/img_14.png" alt=""></div>
            <div class="center-flex">
              <div class="center-border" @click="expectation">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M3.33341 9.99996C3.33341 6.31806 6.31818 3.33329 10.0001 3.33329C13.682 3.33329 16.6667 6.31806 16.6667 9.99996C16.6667 13.6819 13.682 16.6666 10.0001 16.6666C6.31818 16.6666 3.33341 13.6819 3.33341 9.99996ZM10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663ZM8.33664 6.36348C8.0789 6.21313 7.76044 6.21206 7.5017 6.36067C7.24295 6.50929 7.08342 6.7849 7.08342 7.08329V12.9166C7.08342 13.215 7.24295 13.4906 7.5017 13.6392C7.76044 13.7879 8.0789 13.7868 8.33664 13.6364L13.3366 10.7198C13.5927 10.5704 13.7501 10.2963 13.7501 9.99996C13.7501 9.70357 13.5927 9.42949 13.3366 9.28014L8.33664 6.36348ZM11.2629 9.99996L8.75008 11.4658V8.53416L11.2629 9.99996Z" fill="#2F5AFE"/>
                </svg>
                观看案例
              </div>
            </div>
          </div>
          <div class="center-setting">
          <div class="center-title">数字小麦</div>
          <div class="center-text">打造智慧小麦管理服务应用场景</div>
          <div><img class="center-img" src="../assets/home/img_15.png" alt=""></div>
          <div class="center-flex">
            <div class="center-border" @click="expectation">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M3.33341 9.99996C3.33341 6.31806 6.31818 3.33329 10.0001 3.33329C13.682 3.33329 16.6667 6.31806 16.6667 9.99996C16.6667 13.6819 13.682 16.6666 10.0001 16.6666C6.31818 16.6666 3.33341 13.6819 3.33341 9.99996ZM10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663ZM8.33664 6.36348C8.0789 6.21313 7.76044 6.21206 7.5017 6.36067C7.24295 6.50929 7.08342 6.7849 7.08342 7.08329V12.9166C7.08342 13.215 7.24295 13.4906 7.5017 13.6392C7.76044 13.7879 8.0789 13.7868 8.33664 13.6364L13.3366 10.7198C13.5927 10.5704 13.7501 10.2963 13.7501 9.99996C13.7501 9.70357 13.5927 9.42949 13.3366 9.28014L8.33664 6.36348ZM11.2629 9.99996L8.75008 11.4658V8.53416L11.2629 9.99996Z" fill="#2F5AFE"/>
              </svg>
              观看案例
            </div>
          </div>
          </div>
          <div class="center-setting" style="border-radius: 0 8px 8px 0;border: 0;">
          <div class="center-title">数字果园</div>
          <div class="center-text">打造智慧果园创新应用示范基地</div>
          <div><img class="center-img" src="../assets/home/img_16.png" alt=""></div>
          <div class="center-flex">
            <div class="center-border" @click="expectation">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M3.33341 9.99996C3.33341 6.31806 6.31818 3.33329 10.0001 3.33329C13.682 3.33329 16.6667 6.31806 16.6667 9.99996C16.6667 13.6819 13.682 16.6666 10.0001 16.6666C6.31818 16.6666 3.33341 13.6819 3.33341 9.99996ZM10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663ZM8.33664 6.36348C8.0789 6.21313 7.76044 6.21206 7.5017 6.36067C7.24295 6.50929 7.08342 6.7849 7.08342 7.08329V12.9166C7.08342 13.215 7.24295 13.4906 7.5017 13.6392C7.76044 13.7879 8.0789 13.7868 8.33664 13.6364L13.3366 10.7198C13.5927 10.5704 13.7501 10.2963 13.7501 9.99996C13.7501 9.70357 13.5927 9.42949 13.3366 9.28014L8.33664 6.36348ZM11.2629 9.99996L8.75008 11.4658V8.53416L11.2629 9.99996Z" fill="#2F5AFE"/>
              </svg>
              观看案例
            </div>
          </div>
          </div>
          </el-carousel-item>
        </el-carousel>
        <div class="home-bottom">
          <div id="a3" class="top-title" style="padding-top: 38px;margin-bottom: 64px;">APP下载</div>
          <div class="bottom-img">
            <img src="../assets/home/img_19.png" alt="">
          </div>
          <div class="bottom-flex">
            
            <div class="bottom-flex-module">
              <div class="bottom-flex-modules">
                <div class="bottom-top">
                  <img src="../assets/home/img_21.png" alt="">
                  <div>
                    <el-button type="primary" @click="byClick">Android下载</el-button>
                  </div>
                </div>
                <div class="bottom-flex-indicate"></div>
              </div>
              白蚁防控APP下载
            </div>
            <div class="bottom-flex-module">
              <div class="bottom-flex-modules">
                <div class="bottom-top">
                  <img src="../assets/home/img_22.png" alt="">
                  <div>
                    <el-button type="primary" @click="zxtClick">Android下载</el-button>
                  </div>
                </div>
                <div class="bottom-flex-indicate"></div>
              </div>
              植信通APP下载
            </div>
            <div class="bottom-flex-module">
              <div class="bottom-flex-modules">
                <div class="bottom-top">
                  <img src="../assets/home/img_24.png" alt="">
                  <div>
                    <el-button type="primary" @click="ymClick">Android下载</el-button>
                  </div>
                </div>
                <div class="bottom-flex-indicate"></div>
              </div>
              疫木除治APP下载
             </div>
            <div class="bottom-flex-module">
              <div class="bottom-flex-modules">
                <div class="bottom-top">
                  <img src="../assets/home/img_25.png" alt="">
                  <div>
                    <el-button type="primary" @click="gsClick">Android下载</el-button>
                  </div>
                </div>
                <div class="bottom-flex-indicate"></div>
              </div>
              古树宝APP下载
            </div>
          </div>
        </div>
        <div class="home-introduce" id="a4">
          <div class="home-introduce-flex">
            <div class="home-introduce-img">
              <img src="../assets/home/img_20.png" alt="">
            </div>
            <div class="home-introduce-module">
              <div>
                <div class="home-introduce-title">关于我们</div>
                <div class="home-introduce-text" style="width: 430px;">
                  山东祥辰科技集团有限公司（原济南祥辰科技有限公司）成立于2008年，是一家以“生态引领、数字赋能”为发展主旨的国家级高新技术企业，公司以有害生物大数据构建为导向，将工业4.0应用于有害生物全产业链、全过程服务，打造智慧农林数字云服务，为智慧农林、数字农业、土壤修复、生态治理等提供完善的一站式服务。
                </div>
              </div>
              <div style="text-align: center;">
                <div class="home-introduce-title">支持与服务</div>
                <div class="home-introduce-text"><a href="http://www.jnxiangchen.com/product/" target="blank">产品中心</a></div>
                <div class="home-introduce-text"><a href="http://www.jnxiangchen.com/case/" target="blank">工程案例</a> </div>
                <div class="home-introduce-text"><a href="http://www.jnxiangchen.com/cbfw/" target="blank">技术服务</a> </div>
                <div class="home-introduce-text"><a href="http://www.jnxiangchen.com/feedback/" target="blank">意见反馈</a></div>
              </div>
              <div>
                <div style="text-align: center;">
                  <div class="home-introduce-title">友情链接</div>
                  <div class="home-introduce-text"><a href="http://www.moa.gov.cn/" target="blank">中华人民共和国农业农村部</a> </div>
                  <div class="home-introduce-text"><a href="http://www.agri.cn/" target="blank">中国农业信息网</a> </div>
                  <div class="home-introduce-text"><a href="http://entsoc.ioz.ac.cn/" target="blank">中国昆虫协会</a> </div>
                  <div class="home-introduce-text"><a href="http://www.zgzbao.com/" target="blank">植保技术信息网</a> </div>
                </div>
              </div>
              <div>
                <div class="home-introduce-title">联系我们</div>
                <div class="home-introduce-text introduce-text-flex">
                  <div><img src="../assets/home/img_26.png" alt=""> 电话：18605315147</div>
                  <div><img src="../assets/home/img_27.png" alt=""> 服务热线：0531-66675998</div>
                </div>
                <div class="home-introduce-text">公司地址：山东省高新区工业南路山钢新天地广场7-1-402</div>
              </div>
            </div>
          </div>
        </div>
        <div class="home-copyright">©2019山东祥辰科技集团有限公司所有 鲁ICP备12007948号-1</div>
    <template>
      <el-backtop :bottom="80"></el-backtop>
    </template>
  </div>
</template>

<script>
import Navigation from '@/components/Navigations.vue'

export default {
  name: 'HomeView',
  components: {
    Navigation
  },
  methods: {
    // 植信通云平台
    zxtClicks() {
      window.open("https://zxt.jnxiangchen.com/#/login");
    },
    // 农业四情信息服务云平台
    nysqClick() {
      window.open("https://zxt.jnxiangchen.com/#/login-sq");
    },
    // 智慧林防云服务平台
    zhsfClick() {
      window.open("https://zxt.jnxiangchen.com/#/login-zhsf");
    },
    // 美国白蛾检测预警系统
    mgbeClick() {
      window.open("https://zxt.jnxiangchen.com/#/login-mgbe");
    },
    // 迁飞害虫精准雷达监测系统
    qfhcClick() {
      window.open("https://zxt.jnxiangchen.com/#/login-qfhc");
    },
    // 白蚁全生命周期监控云平台
    byCkicks() {
      window.open("http://by.xcforest.com/#/");
    },
    // 松墨天牛检测预警系统
    smtnClick() {
      window.open("https://zxt.jnxiangchen.com/#/login-smtn");
    },
    // 古树名木智慧监管云平台
    gsClicks() {
      window.open("http://gs.xcforest.com/#/user/login");
    },
    // 疫木处置全流程追溯平台
    ymClicks() {
      window.open("http://pwn.xcforest.com/#/");
    },
    // 5G昆虫体验馆
    kcClick() {
      window.open("https://5g.qianqianyun.cn/#/index");
    },
    // 有害生物智能监测预警系统
    yjClick() {
      window.open("http://x.xcforest.com/Login/Index");
    },
    // 古树医院
    gsHospital() {
      window.open("http://gsyy.xcforest.com/#/");
    },
    // 敬请期待~
    expectation() {
      this.$message.success('敬请期待~')
    },
    // 白蚁防控APP下载
    byClick() {
      window.location.href = "http://yun.xcforest.com/apk/by.apk";
    },
    // 植信通APP下载
    zxtClick() {
      window.location.href = "http://yun.xcforest.com/apk/ZxtAppV1.0.0.apk";
    },
    // 疫木除治APP下载
    ymClick() {
      window.location.href = "http://yun.xcforest.com/apk/ymcz_app.apk";
    },
    // 古树宝APP下载
    gsClick() {
      window.location.href = "http://yun.xcforest.com/apk/gsyy-app.apk";
    }
  }
}
</script>

<style scoped lang="less">
.home {
  overflow: auto;
  height: 100%;
}
@media screen and (max-width: 1728px) {
  .home {
    width: 1728px;
  }
}
.home-top {
  width: 100%;
  height: 600px;
  background: url('../assets/home/img_1.png') no-repeat;
  background-size: 100% 100%;
}
  .top-title {
    font-size: 36px;
    font-family: "PingFangSC-Medium";
    font-weight: 600;
    color: #1D2129;
    padding-top: 149px;
    margin-bottom: 39px;
    text-align: center;
  }
  .top-center {
    width: 88%;
    height: 304px;
    margin: 0 auto;
    background: rgba(255,255,255,0.8);
    border-radius: 8px 8px 8px 8px;
    border: 2px solid #FFFFFF;
    .top-center-side {
      width: 97%;
      margin: 13px auto;
      .top-center-flex {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        .center-flex-module {
          width: 312px;
          height: 88px;
          line-height: 88px;
          background: #F2F3F5;
          border-radius: 4px;
          font-size: 16px;
          font-family: "PingFangSC-Medium";
          font-weight: 400;
          color: #1D2129;
          margin: 0 12px 14px 0;
          cursor: pointer;
          .img0 {
            display: inline-block;
          }
          .img1 {
            display: none;
          }
          img {
            width: 36px;
            height: 36px;
            vertical-align: middle;
            margin-left: 18px;
          }
        }
        .center-flex-module:hover {
          background: #2F5AFE;
          color: #ffffff;
          .img0 {
            display: none;
          }
          .img1 {
            display: inline-block;
          }
        }
      }
    }
  }
  .top-center-title {
    font-size: 18px;
    font-family: "PingFangSC-Medium";
    font-weight: 600;
    color: #1D2129;
    img {
      width: 40px;
      height: 40px;
      vertical-align: middle;
    }
  }
  .top-center-other {
    width: 88%;
    margin: 16px auto;
    display: flex;
    .other-module {
      width: 320px;
      height: 148px;
      background: #FFFFFF;
      box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.08);
      border-radius: 8px;
      margin-right: 18px;
      cursor: pointer;
      .top-center-img {
        width: 36px;
        height: 36px;
        vertical-align: middle;
        margin-left: 18px;
      }
      .top-center-text {
        margin: 16px 18px 0;
        font-size: 14px;
        font-family: "PingFangSC-Medium";
        font-weight: 400;
        color: #4E5969;
      }
    }
    .other-module:hover {
      .top-center-title {
        color: #2F5AFE;
      }
    }
    .other-modules {
      background: #F7F8FA;
      line-height: 148px;
      text-align: center;
      font-size: 18px;
      font-family: "PingFangSC-Medium";
      font-weight: 600;
      color: #C9CDD4;
    }
  }
.home-center {
  
  .center-setting {
    width: 419px;
    height: 396px;
    background: #F7F8FA;
    text-align: center;
    border-right: 1px solid #E5E6EB;
    border-radius: 8px 0 0 8px;
    .center-title {
      font-size: 20px;
      font-family: "PingFangSC-Medium";
      font-weight: 600;
      color: #1D2129;
      padding-top: 29px;
    }
    .center-text {
      font-size: 14px;
      font-family: "PingFangSC-Medium";
      font-weight: 400;
      color: #4E5969;
      margin: 10px 0;
    }
    .center-img {
      width: 332px;
      height: 186px;
      margin-top: 20px;
    }
    .center-flex {
      display: flex;
      justify-content: flex-end;
      width: 80%;
      margin: 0 auto;
    }
    .center-border {
      width: 140px;
      height: 44px;
      line-height: 44px;
      border-radius: 4px;
      border: 1px solid #2F5AFE;
      font-size: 16px;
      font-family: "PingFangSC-Medium";
      font-weight: 400;
      color: #2F5AFE;
      margin-top: 15px;
      cursor: pointer;
      svg {
        width: 17px;
        height: 17px;
        vertical-align: middle;
      }
    }
  }
  .center-setting:hover {
    background: rgba(0,117,255,0.04);
    .center-title {
      color: #2F5AFE;
    }
    .center-border {
      background: #2F5AFE;
      color: #ffffff;
      path {
        fill: #ffffff;
      }
    }
  }
}
.home-bottom {
  width: 100%;
  height: 312px;
  background: #F7F8FA;
  margin-top: 28px;
  position: relative;
  .bottom-img {
    position: absolute;
    right: 1%;
    top: 8%;
    img {
      width: 208.51px;
      height: 238.63px;
    }
  }
  .bottom-flex {
    width: 88%;
    height: 100px;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 8px;
    position: absolute;
    z-index: 99;
    left: 6%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 20px;
    font-family: "PingFangSC-Medium";
    font-weight: 600;
    color: #1D2129;
    .bottom-flex-modules {
      position: absolute;
      bottom: 86%;
      // left: -4%;
      left: 20%;
      display: none;
    }
    .bottom-top {
      // width: 336px;
      width: 184px;
      height: 244px;
      background: #FFFFFF;
      border-radius: 8px;
      box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.08);
      line-height: 45px;
      img {
        width: 107px;
        height: 107px;
        border-radius: 4px;
        border: 1px solid #E5E6EB;
        margin-top: 32px;
      }
      .el-button {
        width: 120px;
        height: 44px;
        background: #2F5AFE;
        border-radius: 4px;
      }
    }
    .bottom-flex-indicate {
      width: 0px;
      height: 0px;
      margin: 0 auto;
      border: 12px solid transparent;
      border-top-color: #ffffff;
    }
    .bottom-flex-module {
      width: 304px;
      height: 68px;
      line-height: 68px;
      border-radius: 8px;
      text-align: center;
      position: relative;
    }
    .bottom-flex-module:hover {
      color: #2F5AFE;
      background: rgba(0, 117, 255, 0.04);
      cursor: context-menu;
      .bottom-flex-modules {
        display: block;
      }
    }
  }
}
.home-introduce {
  width: 100%;
  height: 166px;
  background: #FFFFFF;
  padding: 35px 0;
  position: relative;
  .home-introduce-flex {
    width: 88%;
    margin: 0 auto;
    .home-introduce-img {
      position: absolute;
      bottom: 10%;
      left: 25%;
      img {
        width: 227px;
        height: 143px;
      }
    }
    .home-introduce-module {
      display: flex;
      position: absolute;
      justify-content: space-between;
      width: 88%;
      .home-introduce-title {
        font-size: 18px;
        font-family: "PingFangSC-Medium";
        font-weight: 600;
        color: #1D2129;
      }
      .home-introduce-text {
        font-size: 14px;
        font-family: "PingFangSC-Medium";
        font-weight: 400;
        line-height: 24px;
        margin-top: 11px;
        a {
          color: #4E5969;
          text-decoration: none;
        }
      }
      .introduce-text-flex {
        width: 361px;
        display: flex;
        justify-content: space-between;
        img {
          width: 13.5px;
          height: 13.5px;
          vertical-align: middle;
        }
      }
    }
  }
}
.home-copyright {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #4E5969;
  text-align: center;
  font-size: 12px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #FFFFFF;
}
::v-deep .el-carousel__indicators {
  display: none;
}
::v-deep .el-carousel__container {
  width: 88%;
  height: 396px;
  background: #F7F8FA;
  border-radius: 8px;
  margin: 0 auto;
}
::v-deep .el-carousel__arrow:hover {
    background: #FFFFFF;
    border: 1px solid rgba(47,90,254,0.1);
}
::v-deep .el-carousel__arrow--left {
  left: -23px;
}
::v-deep .el-carousel__arrow {
  width: 40px;
  height: 40px;
  font-size: 20px;
  color: #E5E6EB;
  background: #FFFFFF;
  border: 1px solid rgba(47,90,254,0.1);
}
::v-deep .el-carousel__arrow--right {
  right: -23px;
}
</style>
